// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sidebar.css */
.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 200px;
    height: 100%;
    background-color: #333;
    color: white;
    transition: transform 0.3s ease;
    transform: translateX(-100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .sidebar.open {
    transform: translateX(0);
  }
  
  .toggle-button {
    margin-bottom: 20px;
    background-color: #555;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .menu {
    width: 100%;
  }
  
  .menu ul {
    list-style-type: none;
    padding: 0;
  }
  
  .menu ul li {
    padding: 10px 0;
    text-align: center;
  }
  
  .menu ul li:hover {
    background-color: #444;
  }
  
  /* Sidebar.css */
.offcanvas {
    width: 250px;
  }
  
`, "",{"version":3,"sources":["webpack://./src/pages/Sidebar.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,eAAe;IACf,OAAO;IACP,MAAM;IACN,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,+BAA+B;IAC/B,4BAA4B;IAC5B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;EACxB;;EAEA,gBAAgB;AAClB;IACI,YAAY;EACd","sourcesContent":["/* Sidebar.css */\n.sidebar {\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 200px;\n    height: 100%;\n    background-color: #333;\n    color: white;\n    transition: transform 0.3s ease;\n    transform: translateX(-100%);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n  }\n  \n  .sidebar.open {\n    transform: translateX(0);\n  }\n  \n  .toggle-button {\n    margin-bottom: 20px;\n    background-color: #555;\n    color: white;\n    border: none;\n    padding: 10px;\n    cursor: pointer;\n  }\n  \n  .menu {\n    width: 100%;\n  }\n  \n  .menu ul {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  .menu ul li {\n    padding: 10px 0;\n    text-align: center;\n  }\n  \n  .menu ul li:hover {\n    background-color: #444;\n  }\n  \n  /* Sidebar.css */\n.offcanvas {\n    width: 250px;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
